.container {
    display: flex;
    flex-direction: column;
    min-height: 62px;
}

.ean-text {
    color: #6b6f93;
    font-feature-settings: clig off, liga off;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.name:hover {
    text-decoration: underline;
}