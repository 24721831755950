.wrapper-button-menu-item {
  display: flex;
  justify-content: flex-end;
}

.button-add-item {
  background-color: #DCEDFF;
  color: #0171E6 !important;
  padding: 10px 24px;
  width: inherit;
  height: 48px;
}

/* permite centrar verticalmente el icon de +  */
.button-add-item > span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-add-item:not(:disabled):hover,
.button-add-item:not(:disabled):focus {
  background-color: #0062C8 !important;
  color: #0171E6 !important
}

.button-add-item:not(:disabled):active {
  background-color: #0056B0 !important;
  color: #0171E6 !important
}

.button-add-item:not(:disabled):hover,
.button-add-item:not(:disabled):focus,
.button-add-item:not(:disabled):active .text-button-item {
  color: #fff !important;
}

.text-button-item {
  padding-left: 4px;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.container-panel {
  margin-top: 2px;
}

.container-quantity-item {
  margin: 16px 8px !important;
}

.quantity-items {
  color: #ACAEC4;
  font-family: SourceSansProRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.wrapper-loader {
  text-align: center;
  margin-top: 5px;
}

/* styles by counter box */
/* SET VALUES DEFAULT  */
/* SET VALUES DEFAULT  */
.custom-input-number input {
  text-align: center;
  overflow: hidden;
  color: var(--neutral-600, #ACAEC4);
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;

  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  min-width: 26px !important;
  width: 26px !important;
  padding: 0px !important;
}

.custom-input-number input:focus {
  color: #40435b;
}

.custom-input-number-complete input {
  color: #40435b;
}

.custom-input-number span {
  display: none !important;
}



/* END */

/* WRAPPER PARENT */
.wrapper-counter {
  display: inline-flex;
  height: 48px; /*40px*/
  width: inherit; /*122px*/
  padding: 10px 0px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-600, #ACAEC4);
  background: var(--neutral-white, #FFF);
}

.wrapper-loader {
  width: 100%;
  text-align: center;
  margin-top: 5px;
}

.wrapper-counter:active {
  color: #000;
}

.wrapper-counter-error {
  border: 1px solid var(--primary-600, #E22E2E) !important;
}

/* END */

/* BUTTONS */
.wrapper-counter button {
  height: 45px;
  width: 40px;
  padding: 8px;
  background: none;
}

.wrapper-counter button:hover {
  background: var(--primary-10, #EBF5FF);
  color: #0171E6;
}

/* BUTTONS */
.wrapper-counter-buttons button, .wrapper-counter-buttons button:first-child, .wrapper-counter-buttons button:last-child {
  color: #0171E6;
}

/* END */


/* CARD */
.name {
  color: #0B2033;
  font-feature-settings: clig off, liga off;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  width: 190px;
  min-height: 45px;
}

.clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}

.drug {
  overflow: hidden;
  color: #6B6F93;
  text-overflow: ellipsis;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1; 
  -webkit-box-orient: vertical;
  word-break: break-all;
  width: 190px;
  padding-top: 4px;
}