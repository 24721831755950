.container-offer-label{
  width: 100%;
  min-height: 20px;
  max-height: 20px;
  margin-bottom: 8px;
}
.box-offer-label {
  /* position: absolute;
  top:20;
  left:0; */
    display: flex;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: #ff6300;
    min-height: 24px;
    max-height: 24px;
    border-radius: 4px ;
    overflow: hidden;
    width: 100%;
    margin-bottom: 8px;
  }
  
  .text-offer-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 210px;
  }

  .box-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: transparent;
    visibility: hidden;
    min-height: 24px;
    width: 0;
  }
  
  .empty-offer-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 24px;
    
  }
  