/* parent */
.container {
    width: 100%;
    display: flex;
    position: relative;
    width: 190px;
}

.parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
}

.child {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    color: gray;
    width: 100%;
}

.child > div > p {
    margin: 0 !important;
    width: 70% !important;
}

.child:first-child > div > p {
    margin: 0 !important;
    width: 50% !important;
}

.child-two > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 24px;
    color: gray;
    width: 100%;
    gap: 5px;
    margin-bottom: 5px;
}

.child-two > div > p:first-child {
    margin: 0 !important;
    width: 100% !important;
}

.child-two > div > p:last-child {
    margin: 0 !important;
    width: 50% !important;
}

.child-offer-label {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    color: gray;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

.child-offer-label > div {
    display: flex;
    justify-content: flex-end;
}

.child-offer-label > div > p {
    border-radius: 0px 8px 0px 4px;
    margin: 0 !important;
    width: 70% !important;
}

.child-offer-label:first-child > div > p {
    margin: 0 !important;
    width: 50% !important;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
    width: 100%;
    height: 48px !important;
}

.child-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 22px;
}
