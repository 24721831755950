/* ------------------ buttons ------------------ */
.carousel-container > button {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
}

.carousel-container button:focus {
    outline: none;
}

.carousel-container button.prev {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 4px solid var(--Primary-500, #84c0fe);
    background: var(--Primary-600, #0171e6);
    transform: rotate(180deg);
    z-index: 1;
    display: none;
    position: absolute;
    top: 40%;
    left: -16px;
}

.carousel-container button.next {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 4px solid var(--Primary-500, #84c0fe);
    background: var(--Primary-600, #0171e6);
    z-index: 1;
    display: none;
    position: absolute;
    top: 40%;
    right: -16px;
}

.carousel-container:hover button.prev {
    display: flex;
}

.carousel-container:hover button.next {
    display: flex;
}

.carousel-container:hover {
    box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1), 0px 1px 2px 0px rgba(64, 67, 91, 0.06);
}

.carousel-container {
    overflow: hidden;
    width: 100%;
    height: 378px;
    border-radius: 8px;
    background: var(--Neutral-white, #fff);
    padding: 16px;
}

.carousel-content {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    transition: transform 0.5s ease-in-out;
}

.carousel-item {
    width: 11.875rem;
    box-sizing: border-box;
    transition: opacity 0.5s ease-in-out;
    display: flex;
    justify-content: center;
}

.carousel-big {
    height: 80vh;
}