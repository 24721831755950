/* parent */
.container {
    width: 11.875rem;
    background-color: #fff;
    display: flex;
    height: 305px;
    position: relative;
}

.box {
    width: 190px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
}

.unit_price {
    min-height: 18px;
    overflow: hidden;
    color: #6b6f93;
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-decoration-line: line-through;

    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
}

.unit_price_dto {
    min-height: 24px;
    overflow: hidden;
    color: #0171e6;
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: 'Source Sans Pro';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding-top: 4px;
    
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
