.excarousel-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: #E0E1E9;
    border-radius: 50%;
    cursor: pointer;
}

.active {
    background-color: #0171E6;
}
